<template>
  <div class="g-box g-role-add-box g-role-add-box-padding" v-loading="loading">
    <div
      class="m-form-inline-mn"
      data-m="client"
      style="margin-top: 0px; padding-top: 0px"
    >
      <div class="page-content">
        <div class="main-content clearfix page">
          <div class="page-co2"></div>
          <div class="page-box" style="margin-top: 30px">
            <div class="search-box">
              <div>
                <input
                  type="text"
                  :placeholder="$t('global.keywordMsg')"
                  v-model.trim="formInline.title"
                  @keydown.enter="toPage(1)"
                  @blur="aaaa"
                  @focus="showClear = true"
                />
                <div
                  v-if="showClear"
                  class="clear-btn"
                  style="
                    display: flex;
                    align-items: center;
                    background: #ffffff;
                    margin-left: -2px;
                    padding: 0 5px;
                  "
                >
                  <i
                    class="el-icon-circle-close"
                    style="font-size: 26px; color: #999999; cursor: pointer"
                    @click="(formInline.title = ''), startSearch()"
                  ></i>
                </div>
                <div class="btn" @click="startSearch">
                  {{ $t("global.searchText") }}
                </div>
              </div>
            </div>
            <div class="content-box" v-if="dataList.length > 0">
              <div class="content">
                <div class="video-content">
                  <div class="video-desc-box">
                    <div class="title">{{ currentData.title }}</div>
                    <div class="clock">
                      {{ $moment(currentData.createTime).format("YYYY-MM-DD") }}
                    </div>
                  </div>
                  <div
                    class="video-box"
                    ref="playerTemp"
                    v-loading="videoLoading"
                  >
                    <div class="aliplayer">
                      <div class="aliplayer-box">
                        <div>
                          <div class="aliplayer-box-error"></div>
                          <div>
                            播放出错啦, 请尝试退出
                            <span @click="reloadVideo">重试</span> 或
                            <span @click="reloadPage">刷新</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-video-box">
                  {{ currentData.introduction }}
                </div>
              </div>
              <div class="list-box">
                <swiper :options="swiperOption" ref="swiper">
                  <swiper-slide
                    @click.native="itemClick(item)"
                    v-for="(item, index) in dataList"
                    :key="item.id"
                  >
                    <div
                      class="video-card"
                      :class="{ is_active: currentData.id === item.id }"
                    >
                      <div class="cover">
                        <img
                          :src="item.coverImgUrl | preUrl"
                          @error="
                            (e) => {
                              e.target.src = require('./../../../assets/img/default-img.png');
                            }
                          "
                          alt=""
                        />
                        <div class="time">{{ item.videoDuration || 0 }}</div>
                      </div>
                      <div class="content">
                        <div class="title norow">
                          {{ item.title || "暂无标题" }}
                        </div>
                        <div class="tag">
                          <div class="tag">
                            <span v-if="item.tag"
                              ><el-tag
                                size="small"
                                v-for="(tag, index) in item.tagList"
                                :key="index"
                                style="margin-right: 10px"
                                >{{ tag }}</el-tag
                              ></span
                            >
                            <span v-else>暂无标签</span>
                          </div>
                        </div>
                        <div class="createTime">
                          {{
                            item.createTime
                              ? $moment(item.createTime).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-navigation navigation-left"></div>
                <div class="swiper-navigation navigation-right"></div>
              </div>
            </div>
            <el-empty v-else :description="$t('global.emptyResMsg')"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '@/assets/img/default-img.png'
import api from '@/api'
import getConfig from '@/config'
function createObjectURL (object) {
  return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object)
}
export default {
  data () {
    return {
      videoLoading: true,
      videoList: [],
      player: null,
      loading: false,
      showClear: false,
      formInline: {
        page: 1,
        size: 30,
        totel: 0,
        title: ''
      },
      activeIndex: 0,
      swiperOption: {
        // centeredSlides: true,
        slidesPerView: 5,
        navigation: {
          nextEl: '.swiper-navigation.navigation-right',
          prevEl: '.swiper-navigation.navigation-left'
        },
        // preventClicksPropagation: false,
        // slideToClickedSlide: true,
        on: {
          transitionStart () {
          },
          slideChange (e) {
            // that.$data.menu_left = !that.$refs['swiper'].swiper.isBeginning;
            // that.$data.menu_right = !that.$refs['swiper'].swiper.isEnd;
          }
        }
      },
      dataList: [],
      currentData: {}
    }
  },
  created () {
  },
  mounted () {
    this.toPage(1)
    // this.playingVideo = videoList[0]
    this.videoLoading = false
  },
  methods: {
    startSearch () {
      this.toPage(1)
    },
    aaaa () {
      setTimeout(_ => {
        this.showClear = false
      }, 300)
    },
    createDemoPlayer (source, cover) {
      if (document.getElementById('player-con') === null) {
        const playerDomWrap = this.$refs.playerTemp
        const playerDom = document.createElement('div')
        playerDom.setAttribute('id', 'player-con')
        playerDomWrap.appendChild(playerDom)

        const props = {
          id: 'player-con',
          width: '100%',
          height: '100%',
          autoplay: false,
          language: 'zh-cn',
          source: source,
          isLive: false,
          cover: cover,
          components: [],
          skinLayout: [
            { name: 'bigPlayButton', align: 'cc' },
            {
              name: 'H5Loading', align: 'cc'
            },
            { name: 'errorDisplay', align: 'tlabs', x: 0, y: 0 },
            { name: 'infoDisplay' },
            { name: 'tooltip', align: 'blabs', x: 0, y: 56 },
            { name: 'thumbnail' },
            {
              name: 'controlBar',
              align: 'blabs',
              x: 0,
              y: 0,
              children: [
                { name: 'progress', align: 'blabs', x: 0, y: 44 },
                { name: 'playButton', align: 'tl', x: 15, y: 12 },
                { name: 'timeDisplay', align: 'tl', x: 10, y: 7 },
                { name: 'fullScreenButton', align: 'tr', x: 10, y: 12 },
                { name: 'setting', align: 'tr', x: 15, y: 12 },
                { name: 'volume', align: 'tr', x: 5, y: 10 }
              ]
            }
          ]

        }
        this.player = new Aliplayer(props)

        this.player.on('init', function (e) {
          document.querySelector('.aliplayer').style.display = 'none'
        })
        this.player.on('error', function (e) {
          document.querySelector('.prism-ErrorMessage').style.display = 'none'
          document.querySelector('.aliplayer').style.display = 'block'

        })
      } else {
        this.player.loadByUrl(source)
        this.player.setCover(cover)
      }
    },
    toPage (page) {
      this.formInline.page = page
      this.getList('ok')
    },
    getList (flag) {
      this.loading = true
      api.tzh.queryList({
        ...this.formInline,
        queryParam: {
          type: 2,
          title: this.formInline.title
        }
      }).then(res => {
        this.formInline.total = res.data.total
        this.loading = false
        res.data.records.forEach(ele => {
          if (ele.tag && ele.tag.indexOf(',') >= 0) {
            ele.tagList = ele.tag.split(',')
          } else {
            ele.tagList = [ele.tag]
          }
        })
        this.dataList = res.data.records
        if (this.dataList.length > 0 && flag === 'ok') {
          const item = this.dataList[0]
          this.getDetils(item)
        }
      })
    },
    itemClick (item) {
      this.getDetils(item)
    },
    getDetils (item) {
      this.currentData = item
      this.loadVideo(item.id)
    },
    loadVideo (id) {
      this.videoLoading = true
      api.tzh.findById({ id }).then(res => {
        this.videoLoading = false
        this.createDemoPlayer(getConfig().ftpUrl + '/' + res.data.transcodeVideoUrl, res.data.coverImgUrl ? this.preUrl(res.data.coverImgUrl) : defaultImg)
      })
    },
    reloadVideo () {
      if (!this.player) {
        return
      }
      document.querySelector('.aliplayer').style.display = 'none'
      document.querySelector('.prism-ErrorMessage').style.display = 'block'
      var url = this.player.getSourceUrl()
      this.player.loadByUrl(url)
    },
    reloadPage () {
      location.href = location.href
    }
  }
}
</script>
<style lang="scss" scoped>
.clock {
  flex-shrink: 0;
  &::before {
    position: relative;
    top: 2px;
    content: "";
    margin-left: 10px;
    margin-right: 2px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url("~assets/img/icon/clock-icon.png") no-repeat center/cover;
  }
}
* {
  box-sizing: border-box;
}
.page-co2 {
  width: 807px;
  height: 671px;
  position: absolute;
  right: -350px;
  top: -100px;
  background: url("~assets/img/tzh/co2.png") 100% 0% no-repeat;
  background-size: 807px 671px;
  z-index: 1;
}
.page-box {
  position: relative;
  z-index: 2;
}
.page {
  width: 100%;
  position: relative;
  /*margin-top: 30px;*/
  /*padding: 0 30px;*/
  /*padding-top: 20px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /*padding-bottom: 66px;*/
  .search-box {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    & > div {
      display: flex;
      justify-content: space-between;
      width: 542px;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      input {
        outline: none;
        border: none;
        flex: 1;
        text-indent: 12px;
        &::-webkit-input-placeholder {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.25);
        }
      }
      .btn {
        text-align: center;
        width: 98px;
        line-height: 48px;
        background: #09a666;
        border-radius: 3px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
  .content-box {
    width: 1200px;
    .content {
      margin: 0 10px;
      box-sizing: border-box;
      display: flex;
      .video-content {
        padding: 15px 24px;
        width: 872px;
        height: 479px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
        border-radius: 4px 4px 4px 4px;
        .title {
          font-size: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 20px;
        }
        .video-desc-box {
          margin: 10px 0;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
        }
        .video-box {
          width: 100%;
          height: 385px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #707070;
          position: relative;
        }
      }
      .content-video-box {
        flex: 1;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1);
        padding: 24px 20px;
        margin-left: 24px;
        height: 478px;
        overflow-y: auto;
        text-indent: 2em;
        width: 256px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 24px;
        background: #ffffff;
      }
    }
  }
  .list-box {
    margin-top: 14px;
    position: relative;
    display: flex;
    .swiper-navigation {
      width: 48px;
      height: 48px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: url("~assets/img/tzh/swiper-arrow.png") no-repeat center/cover;
      cursor: pointer;
      &.swiper-button-disabled {
        opacity: 0.3;
        transition: all 0.3s;
      }
    }
    .navigation-left {
      left: -16px;
      transform: translateX(-100%) rotate(180deg);
    }
    .navigation-right {
      right: -16px;
      transform: translateX(100%);
    }
    .swiper-container {
      width: 100%;
      padding: 10px;
    }
    .video-card {
      &:not(:last-child) {
        margin-right: 14px;
      }
      &.is_active {
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.16);
        transform: scale(1.05);
      }
      width: 221px;
      height: 213px;
      cursor: pointer;
      background: #fafafa;
      border-radius: 4px 4px 4px 4px;
      transition: all 0.3s;
      overflow: hidden;
      &:hover {
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.16);
        transform: scale(1.05);
      }
      .cover {
        position: relative;
        width: 100%;
        height: 118px;
        background: #f6f6f6;
        border-radius: 4px 4px 0px 0px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .time {
          position: absolute;
          right: 0;
          bottom: 0;
          text-align: center;
          width: 72px;
          line-height: 22px;
          background: rgba(0, 0, 0, 0.4);
          color: #ffffff;
          border-radius: 3px 0px 0px 0px;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        .title {
          font-size: 16px;
          margin: 8px 0;
          line-height: 22px;
        }
        .tag {
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.65);
        }
        .createTime {
          margin-top: 5px;
          display: flex;
          align-items: center;
          line-height: 20px;
          &::before {
            content: "";
            margin-right: 8px;
            display: inline-block;
            width: 14px;
            height: 14px;
            background: url("~assets/img/icon/clock-icon.png") no-repeat
              center/cover;
          }
        }
      }
    }
  }
}
</style>
